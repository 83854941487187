import { mapGetters } from 'vuex'

export var PageMixin = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('pages', ['getThePage']),
    thePage() {
      return this.getThePage
    },
  }, // computed
}

export default PageMixin
